import { AnalyticsType } from "types/analytics";

interface AnalyticsTableProps {
  analytics: AnalyticsType[];
}

export function AnalyticsTable({ analytics }: AnalyticsTableProps) {  
  return (
    <div className="table-container">
      <table className="analytics-table">
        <thead>
          <tr>
            <th>Source</th>
            <th>Event</th>
            <th>Data</th>
            <th>Created At</th>
            <th>Updated At</th>
          </tr>
        </thead>
        <tbody>
          {analytics.length > 0 ? (
            analytics.map((item) => (
              <tr key={item.id}>
                <td>{item.source}</td>
                <td>{item.event}</td>
                <td>{JSON.stringify(item.data) || 'N/A'}</td>
                <td>{new Date(item.created_at).toLocaleString()}</td>
                <td>{new Date(item.updated_at).toLocaleString()}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                No hay datos disponibles
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
