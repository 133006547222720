import React, { useState } from "react";
import { FaChevronRight } from 'react-icons/fa';
import { AnalyticsFilterType } from "types/analytics";
import DateRangeSelector from "./DateRangeSelector";

interface LyticsFiltersProps {
  filters: AnalyticsFilterType;
  setFilter: <K extends keyof AnalyticsFilterType>(key: K, value: AnalyticsFilterType[K]) => void;
  sourceList: string[];
  eventList: string[];
}
const LyticsFilter: React.FC<LyticsFiltersProps> = ({
  filters,
  setFilter,
  sourceList,
  eventList,
}) => {
  const [showAllSources, setShowAllSources] = useState(false);

  const visibleSources = showAllSources ? sourceList : sourceList.slice(0, 4);
  const handleShowAllSources = () => setShowAllSources(true);


  return (
    <section>
      <article>
        {/* Campo de búsqueda */}
        <h3>Filtrar</h3>
        <input
          type="text"
          value={filters.search}
          placeholder="Buscar"
          onChange={(e) => setFilter("search", e.target.value)}
          className="search-input"
        />
        <div className="flex-container">
          <div className="source-list">
            {visibleSources.map((source, index) => (
              <div key={index} className="chip">
                {source}
              </div>
            ))}
          </div>
          {!showAllSources && (
            <span className="chevron-right" onClick={handleShowAllSources}>
              <FaChevronRight size={20} />
            </span>
          )}
        </div>
      </article>
      {/* Selector de eventos */}
      <div className="flex-container">
        <div style={{ flex: 1 }}>
          <select
            id="event-select"
            value={filters.event || ""}
            onChange={(e) => setFilter("event", e.target.value || "")}
            className="event-select"
          >
            <option value="">Seleccionar evento</option>
            {eventList.map((event, index) => (
              <option key={index} value={event}>
                {event}
              </option>
            ))}
          </select>
        </div>

        <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
          <DateRangeSelector
            value={filters.window}
            paginationEnable
            onChange={(window) => setFilter("window", window)}
            onPageChange={(newPage) => setFilter("windowPage", newPage)}
          />
        </div>
      </div>
    </section>
  );
}

export default LyticsFilter;