import React, { useState } from "react";
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { TimeWindow } from "types/analytics";


interface Props {
  value: TimeWindow,
  onChange: (value: TimeWindow) => void;
  paginationEnable?: boolean;
  page?: number;
  onPageChange?: (newPage: number) => void;
}

const DateRangeSelector: React.FC<Props> = ({
  value = "day",
  onChange,
  paginationEnable = false,
  page = 1,
  onPageChange,
}) => {
  const [selectedRange, setSelectedRange] = useState<string>(value);

  const handleChange = (range: TimeWindow) => {
    setSelectedRange(range);
    onChange(range);
  };

  const handlePageChange = (increment: boolean) => {
    if (onPageChange) {
      const newPage = increment ? page + 1 : page - 1;
      onPageChange(newPage);
    }
  };

  return (
    <div className="date-range-selector">
      <span
        className={`date-range-item ${selectedRange === "day" ? "active" : ""}`}
        onClick={() => handleChange("day")}
      >
        Día
      </span>
      <span
        className={`date-range-item ${selectedRange === "week" ? "active" : ""}`}
        onClick={() => handleChange("week")}
      >
        Semana
      </span>
      <span
        className={`date-range-item ${selectedRange === "month" ? "active" : ""}`}
        onClick={() => handleChange("month")}
      >
        Mes
      </span>
      {paginationEnable && (
        <div className="pagination-controls">
          <span
            className="chevron-left"
            onClick={() => handlePageChange(false)}
          >
            <FaChevronLeft size={20} />
          </span>
          <span className="page-number">{page}</span>
          <span
            className="chevron-right"
            onClick={() => handlePageChange(true)}
          >
            <FaChevronRight size={20} />
          </span>
        </div>
      )}
    </div>
  );
};

export default DateRangeSelector;
