import { MouseEventHandler, useContext, useState } from 'react'
import { Link, NavLink } from 'react-router-dom'
import { AuthContext } from '../authProvider'
import { CoinIcon } from '../icons/coin'
import { LogInIcon } from '../icons/login'
import { LogOutIcon } from '../icons/logout'
import { CartIcon } from '../icons/cart'
import { XIcon } from '../icons/x'
import { HamburgerIcon } from '../icons/hamburger'
import { useWindowSize } from '@uidotdev/usehooks'
import { Balance } from '../Balance'
import { FinanceContext } from '../financeProvider'
import { GearIcon } from '../icons/gear'
import { PersonIcon } from '../icons/person'
import { GraphIcon } from '../icons/graph'
import { NavHeader, ResponsiveNavBar, Li, Dot } from './styled'
import { BotIcon } from '../icons/bot'

export default function Header() {
  const { isAuthed, logout } = useContext(AuthContext)
  const { pendingCoinTransfers } = useContext(FinanceContext)
  const [navbarVisible, setNavbarVisible] = useState(false)
  const { width } = useWindowSize()

  const breakpoint = 768

  const toggleMobileNavVar: MouseEventHandler<HTMLUListElement> = (e) => {
    if (e && (e.target as HTMLElement).tagName.toLowerCase() === 'summary')
      return
    if ('startViewTransition' in document)
      document.startViewTransition(() => setNavbarVisible((prev) => !prev))
    else setNavbarVisible((prev) => !prev)
  }

  return (
    <NavHeader $visible={navbarVisible}>
      <nav className="topnav">
        {(isAuthed && (
          <>
            <ul>
              <li style={{ padding: 0 }}>
                <Balance />
              </li>
            </ul>
            <NavBarToggle
              isOpen={navbarVisible}
              handleClick={toggleMobileNavVar}
              breakpoint={breakpoint}
            />
            <ResponsiveNavBar
              onClick={toggleMobileNavVar}
              style={{
                display: navbarVisible || width! > breakpoint ? 'flex' : 'none'
              }}
            >
              <Li>
                <NavLink to="analiticas">
                  <GraphIcon height={20} width={20} />
                  &nbsp; Analytics
                </NavLink>
              </Li>
              <Li>
                <NavLink to="depositos">
                  <CartIcon height={20} width={20} />
                  <Dot $display={!!pendingCoinTransfers}>&nbsp; Cash-In</Dot>
                </NavLink>
              </Li>
              <Li>
                <NavLink to="pagos">
                  <CoinIcon height={20} width={20} />
                  &nbsp; Cash-Out
                </NavLink>
              </Li>
              <Li>
                <NavLink to="jugadores">
                  <PersonIcon height={20} width={20} />
                  &nbsp; Jugadores
                </NavLink>
              </Li>
              <Li>
                <NavLink to="bot">
                  <BotIcon height={20} width={20} />
                  &nbsp; Bot
                </NavLink>
              </Li>
              <Li>
                <NavLink to="config">
                  <GearIcon height={18} width={18} />
                  &nbsp; Config
                </NavLink>
              </Li>
              <Li>
                <a href="javascript:;" onClick={logout} className="contrast">
                  Salir &nbsp;
                  <LogOutIcon height={22} width={22} />
                </a>
              </Li>
            </ResponsiveNavBar>
          </>
        )) || (
          <li>
            <Link to="login">
              Ingresar <LogInIcon height={22} width={22} />
            </Link>
          </li>
        )}
      </nav>
    </NavHeader>
  )
}

function NavBarToggle({ isOpen, handleClick, breakpoint }: NavBarToggleProps) {
  const { width } = useWindowSize()

  // Renderizar sólo en ancho de pantallas debajo del breakpoint
  return (
    (width! <= breakpoint && (
      <ul onClick={() => handleClick()}>
        <li style={{ cursor: 'pointer', zIndex: 3 }}>
          {isOpen ? (
            <XIcon height={28} width={28} />
          ) : (
            <HamburgerIcon height={28} width={28} />
          )}
        </li>
      </ul>
    )) || <></>
  )
}

interface NavBarToggleProps {
  isOpen: boolean
  handleClick: CallableFunction
  breakpoint: number
}
