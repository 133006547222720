import "./components/styles.css";
import { ViewHeader } from '../../components/ViewHeader'
import { AnalyticsFilterType, AnalyticsType } from '../../types/analytics'
import Summary from './components/Summary'
import LyticsFilter from './components/LyticsFilter'
import { useAnalytics, useSummary } from './hooks'
import DateRangeSelector from "./components/DateRangeSelector";
import { AnalyticsLineChart } from "./components/AnalyticsLineChart";
import { useEffect, useState } from "react";
import { AnalyticsTable } from "./components/AnalyticsTable";

const Analytics = () => {
  const {
    summary,
    loading,
    setSummaryWindow
  } = useSummary();
  const {
    analytics,
    filters,
    setFilters,
    sourceList,
    eventList,
  } = useAnalytics();
  const [chartData, setChartData] = useState<any[]>([])

  const setFilter = <K extends keyof AnalyticsFilterType>(key: K, value: AnalyticsFilterType[K]) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const processAnalyticsData = (data: AnalyticsType[]) => {
    // Agrupar por fecha y contar los eventos
    const groupedData = data.reduce((acc: any, item) => {
      const date = item.created_at.split('T')[0] // Extraer solo la fecha (ej: "2024-12-04")
      if (!acc[date]) {
        acc[date] = { name: date, value: 0 }
      }
      acc[date].value += 1 // Incrementar el contador de eventos para esa fecha
      return acc
    }, {})

    // Convertir el objeto agrupado en un array
    const result = Object.values(groupedData)

    return result
  }
  
  useEffect(() => {
    const formattedData = processAnalyticsData(analytics)
    setChartData(formattedData)
  }, [analytics])
  
  
  return (
    <>
      <ViewHeader loading={loading} heading="Análisis de Tráfico" />
      <DateRangeSelector
        value={"day"}
        onChange={(window) => setSummaryWindow(window)}
      />
      <Summary
        summary={summary}
      />
      <LyticsFilter
        filters={filters}
        setFilter={setFilter}
        sourceList={sourceList}
        eventList={eventList}
      />
      {chartData.length > 0 && <AnalyticsLineChart data={chartData} />}
      <div>
        <h3>Lista de Analíticas</h3>
        <AnalyticsTable analytics={analytics} />
      </div>
      {/* <h2>Lista</h2>
      <Table<IAnalytics>
        RowElement={Analytic}
        entityNamePlural="eventos"
        searchPlaceholder="Buscar por fuente, evento o data"
        loading={loading}
        setLoading={()=>{}}
        url="/analytics"
        columns={[
          { selector: 'source', type: 'string', name: 'Fuente' },
          {
            selector: 'event',
            type: 'string',
            name: 'Evento'
          },
          { name: 'Data' },
          {
            selector: 'created_at',
            type: 'datetime',
            name: 'Fecha'
          }
        ]}
        filter={filter}
      /> */}
    </>
  )
}
export default Analytics;

// const styles = {
//   container: {
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     overflow: 'hidden',
//   },
//   list: {
//     display: 'flex',
//     flexDirection: 'row',
//     overflowX: 'auto',
//     whiteSpace: 'nowrap',
//   },
//   scrollButton: {
//     cursor: 'pointer',
//     backgroundColor: '#e0e0e0',
//     padding: '8px',
//     borderRadius: '50%',
//   },
// };