import { useContext, useEffect, useState } from 'react'
import { AuthContext } from 'components/authProvider'
import { AnalyticsFilterType, AnalyticsType, SortDirection } from 'types/analytics'
import { toast } from 'react-toastify'

export const useAnalytics = () => {
  const { apiInit } = useContext(AuthContext)
  const [analytics, setAnalytics] = useState<AnalyticsType[]>([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState<AnalyticsFilterType>({
    page: 1,
    items_per_page: 20,
    search: "",
    sort_column: "created_at" as "created_at" | "source" | "event",
    sort_direction: "asc" as SortDirection,
    source: "",
    event: "",
    window: "week" as "day" | "week" | "month",
    windowPage: 0,
  });

  const [sourceList, setSourceList] = useState<string[]>([]);
  const [eventList, setEventList] = useState<string[]>([]);

  const fetchAnalytics = async () => {
    try {
      setLoading(true);
      const api = await apiInit()
      const response = await api.get<{ data: { result: AnalyticsType[]}; total: number }>("/analytics", {
        params: filters,
      });

      if (response.status === 200) {
        const analyticsData = response.data.data.result;
        setAnalytics(analyticsData);
        const uniqueSources = Array.from(new Set(analyticsData.map((item) => item.source)));
        const uniqueEvents = Array.from(new Set(analyticsData.map((item) => item.event)));

        setSourceList(uniqueSources);
        setEventList(uniqueEvents);
      } else {
        handleError(response.status);
      }

    } catch (e) {
      toast.error('Error de conexión')
    } finally {
      setLoading(false);
    }
  }

  const handleError = (status: number) => {
    if (status === 401) {
      toast.warn('Sesión expirada.');
    } else if (status >= 500) {
      toast.error('Error de servidor, intentá de nuevo en unos minutos.');
    } else {
      toast.error('No pudimos descargar el resumen de analíticas.');
    }
  };

  useEffect(() => {
    fetchAnalytics();
  }, [filters]);


  return {
    analytics,
    filters,
    setFilters,
    loading,
    sourceList,
    eventList
  };
}
