import { useContext, useEffect, useRef, useState } from 'react'
import { Deposito } from './deposito'
import { ViewHeader } from '../../components/ViewHeader'
import { FinanceContext } from '../../components/financeProvider'
import { PendingCoinTransfers } from './pendingCoinTransfers'
import { COIN_TRANSFER_STATUS, config } from '../../config'
import { Table } from '../../components/Table'
import { Deposit } from '../../types/deposit'
import { TableData } from '../../types/table'
import { depositEventTarget, reloadEvent } from './utils'

export function Depositos() {
  const [loading, setLoading] = useState(false)
  const [seed, setSeed] = useState(0)

  const { pendingCoinTransfers } = useContext(FinanceContext)

  const filter = (
    deposits: TableData<Deposit>,
    searchingBy: string,
    filteringBy?: string
  ) => {
    const filtered = deposits.all.filter((d) =>
      filteringBy === 'all'
        ? true
        : d.status === filteringBy || d.CoinTransfer.status === filteringBy
    )

    if (!searchingBy) {
      return filtered
    }

    return filtered.filter(
      (p) =>
        p.Player.username.toLowerCase().includes(searchingBy) ||
        p.Player.first_name?.toLowerCase()?.includes(searchingBy) ||
        p.Player.last_name?.toLowerCase()?.includes(searchingBy) ||
        p.tracking_number.toLowerCase().includes(searchingBy)
    )
  }

  const increaseSeed = () => setSeed((prev) => prev + 1)

  useEffect(() => {
    depositEventTarget.addEventListener(reloadEvent.type, increaseSeed)

    return () => {
      depositEventTarget.removeEventListener(reloadEvent.type, increaseSeed)
    }
  })

  return (
    <>
      {!!pendingCoinTransfers && (
        <PendingCoinTransfers amount={pendingCoinTransfers} />
      )}

      <ViewHeader
        loading={loading}
        heading={'Historial de Depositos'}
        subheading="Jugador 👉 plataforma"
      />
      <Table
        key={seed}
        RowElement={Deposito}
        entityNamePlural="depositos"
        loading={loading}
        setLoading={setLoading}
        columns={[
          { name: 'Nº de seguimiento' },
          { selector: 'created_at', name: 'Fecha', type: 'datetime' },
          { selector: 'Player.username', name: 'Usuario', type: 'string' },
          { selector: 'amount', name: 'Monto', type: 'number' },
          { name: 'Estado' },
          { selector: 'updated_at', name: 'Fichas enviadas', type: 'datetime' },
          { selector: 'cep_ok', name: 'CEP', type: 'number' }
        ]}
        searchPlaceholder="Buscar por usuario, nombre o número de seguimiento"
        url="/transactions/deposit"
        filter={filter}
        defaultFilter={config.SD.DEPOSIT_STATUS.PENDING}
        quickFilters={[
          {
            label: 'Pendientes',
            value: config.SD.DEPOSIT_STATUS.PENDING,
            ref: useRef()
          },
          {
            label: 'Completados',
            value: COIN_TRANSFER_STATUS.COMPLETED,
            ref: useRef()
          },
          {
            label: 'Todos',
            value: 'all',
            ref: useRef()
          }
        ]}
      />
    </>
  )
}
