import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

interface AnalyticsLineChartProps {
  data: Array<{ name: string, value: number }>;
}

export function AnalyticsLineChart({ data }: AnalyticsLineChartProps) {
  return (
    <article style={{ width: "100%", height: 400 }}>
      <ResponsiveContainer>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="4 4" stroke="#7775" />
          <XAxis dataKey="name" stroke="var(--pico-color)" />
          <YAxis stroke="var(--pico-color)" />
          <Line
            type="monotone"
            dataKey="value" // Asume que cada punto tiene una clave `value`
            stroke="#a35"
            strokeWidth={2.125}
            activeDot={{ r: 6 }}
          />
          <Legend />
          <Tooltip />
        </LineChart>
        
      </ResponsiveContainer>
    </article>
  )
}
